<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left"
        >Archivos cargados al layout {{ layout.nombre }}</v-card-title
      >
      <CButton
        v-if="permiteEjecutar"
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        ><CIcon name="cil-user" /> Nuevo</CButton
      >

      <v-card-text>
        <filters
          :campos="filterFields"
          :itemsKeys="{
            text: 'value',
            value: 'validacion',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>

        <div>
          <v-alert
            v-if="!permiteEjecutar"
            v-model="alert"
            border="left"
            close-text="Close Alert"
            type="info"
            dark
            dismissible
          >
            Se están ejecutando procesos.  Una vez finalicen estarán disponibles las opciones de carga de archivos y generación de variables.
          </v-alert>
        </div>

        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          sorter
          :pagination="{ doubleArrows: false, align: 'center','sortBy': 'id','descending':true }"
          @page-change="pageChange"
        >
        <template #fechaCreacion="data">
            <td>
              {{ obtenerFechaCorta(data.item.fechaCreacion) }}
            </td>
        </template>

        <template #validacion="data">
            <td>
              <span
                v-if="data.item.validacion == 'Pendiente'"
                class="spinner-border spinner-border-sm bottom"
                role="status"
              ></span>

              <CBadge
                v-c-tooltip.hover.click="data.item.validacion"
                v-if="data.item.validacion != 'Pendiente'"
                :color="data.item.validacion == 'Validado' || data.item.validacion == 'CSV Generado' ? 'success' : 'danger'"
                v-c-tooltip.hover.bottom="data.item.log_validacion?data.item.log_validacion:data.item.estado " >
                {{ data.item.validacion }}
              </CBadge>

              

             
            </td>
        </template>

        <template #acciones="data">
          <td>
            <CLink
              href="#"
              @click="descargar(data.item)"
              class="card-header-action btn-setting"
              v-c-tooltip.hover.click="'Descargar'"
            >
              <CIcon name="cil-file" />
            </CLink>
            <CLink
              href="#"
              @click="preEliminar(data.item)"
              class="card-header-action btn-setting"
              v-c-tooltip.hover.click="'Eliminar'"
            >
              <CIcon name="cil-" />X
            </CLink>
            
            <CLink
              v-if="data.item.fecha_inicio_carga_bd == null && layout.id != '62' && data.item.validacion == 'Validado' "
              href="#"
              @click="cargar_bd(data.item)"
              class="card-header-action btn-setting"
              v-c-tooltip.hover.click="'Cargar en tabla de base de datos'"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  class="color-svg"><path d="M20 13.09V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C12.46 21 12.9 21 13.33 20.94C13.12 20.33 13 19.68 13 19L13 18.95C12.68 19 12.35 19 12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.65 16 13.27 15.96 13.88 15.89C14.93 14.16 16.83 13 19 13C19.34 13 19.67 13.04 20 13.09M18 12.45C16.7 13.4 14.42 14 12 14S7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V12.45M12 9C8.13 9 6 7.5 6 7S8.13 5 12 5 18 6.5 18 7 15.87 9 12 9M22 18H20V22H18V18H16L19 15L22 18Z" /></svg>
            </CLink>
            <CLink
              v-if="data.item.fecha_inicio_carga_bd == null  && layout.id == '62' && data.item.estado == 'En Calidad'"
              href="#"
              @click="cargar_bd(data.item)"
              class="card-header-action btn-setting"
              v-c-tooltip.hover.click="'Cargar en tabla de base de datos'"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  class="color-svg"><path d="M20 13.09V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C12.46 21 12.9 21 13.33 20.94C13.12 20.33 13 19.68 13 19L13 18.95C12.68 19 12.35 19 12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.65 16 13.27 15.96 13.88 15.89C14.93 14.16 16.83 13 19 13C19.34 13 19.67 13.04 20 13.09M18 12.45C16.7 13.4 14.42 14 12 14S7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V12.45M12 9C8.13 9 6 7.5 6 7S8.13 5 12 5 18 6.5 18 7 15.87 9 12 9M22 18H20V22H18V18H16L19 15L22 18Z" /></svg>
            </CLink>
            &nbsp;<span
              v-if="data.item.fecha_inicio_carga_bd != null && data.item.fecha_fin_carga_bd == null"
              class="spinner-border spinner-border-sm"
              role="status"
              v-c-tooltip.hover.click="`Cargando en tabla de base de datos desde: ${data.item.fecha_inicio_carga_bd}`"
            ></span>
            <span 
              v-if="data.item.fecha_inicio_carga_bd != null && data.item.fecha_fin_carga_bd != null"
              v-c-tooltip.hover.click="`Cargado en tabla de base de datos: ${data.item.fecha_inicio_carga_bd} - ${data.item.fecha_fin_carga_bd}`"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  class="color-svg"><path d="M12 3C16.42 3 20 4.79 20 7S16.42 11 12 11 4 9.21 4 7 7.58 3 12 3M4 9C4 11.21 7.58 13 12 13S20 11.21 20 9V12.08L19 12C16.41 12 14.2 13.64 13.36 15.94L12 16C7.58 16 4 14.21 4 12V9M4 14C4 16.21 7.58 18 12 18H13C13 19.05 13.27 20.04 13.75 20.9L12 21C7.58 21 4 19.21 4 17V14M18 21.08L15.25 18.08L16.41 16.92L18 18.5L21.59 14.92L22.75 16.33L18 21.08" /></svg>
            </span>
            
          </td>
        </template>

          <template #variables="data" >
            <td>
              &nbsp;<span
                v-if="data.item.estado == 'Pendiente'"
                class="spinner-border spinner-border-sm"
                role="status"
              ></span>
              <CLink
                v-if="data.item.validacion == 'Validado'  && data.item.estado != 'Pendiente'"
                href="#"
                @click="generarVariables(data.item, data.index)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Generar Variables'"
              >
                <CIcon name="cil-settings" />
              </CLink>
              <CLink
                v-if="data.item.estado == 'Procesado' || data.item.estado == 'En Calidad' "
                href="#"
                @click="descargarVariables(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Descargar Variables Generadas'"
              >
                <CIcon name="cil-file" />
              </CLink>  
            </td>
          </template>
        </CDataTable>
      </v-card-text>
      <v-divider></v-divider>
      <div v-if="false">
        <template v-for="(icon, iconName) in $options.freeSet">
          <CCol class="mb-5" col="3" sm="2" :key="iconName">
            <CIcon :height="42" :content="icon" />
            <div>{{ toKebabCase(iconName) }}</div>
          </CCol>
        </template>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="goBack">Volver</CButton>&nbsp;
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea eliminar el archivo?
        </v-card-title>

        <!-- <v-card-text>
        </v-card-text> -->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="gray darken-1" text @click="eliminar"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br /><br />
  </div>
</template>

<script>
import Filters from "@/components/filters";
import EjecucionProcesoCalidadService from "@/services/ejecucionProcesoCalidad.service";
import ArchivoService from "@/services/archivo.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";
import { freeSet } from "@coreui/icons";
import api from "@/services/api";

export default {
  data() {
    return {
      layout: {
        nombre: undefined,
      },
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "fechaCreacion", label: "Fecha" },
        { key: "usuario", label: "Usuario" },
        { key: "nombre", label: "Archivo", _classes: "font-weight-bold" },
        { key: "validacion", label: "Validación" },
        { key: "acciones", label: "Acciones" },
        { key: "variables", label: "Variables" },
      ],
      filterFields: [
        { value: "id", label: "Id de Carga" },
        {
            value: "validacion",
            label: "Validado",
            options: [
              { validacion: 'Validado', value: "Validado" },
              { validacion: 'Error', value: "Error" },
              { validacion: 'Pendiente', value: "En proceso" },
            ],
        },
      ],
      activePage: 1,
      loading: false,
      ejecucionesPendientes: undefined,
      archivosPendientes: undefined,
      permiteEjecutar: true,
      alert: false,
      message: "",
      dialog: false,
      deleteItem: undefined,
    };
  },
  api,
  freeSet,
  components: {
    Filters,
  },
  watch: {
    "ejecucionesPendientes"(newVal) {
      if(this.ejecucionesPendientes != undefined && this.archivosPendientes != undefined) {
        if(!this.ejecucionesPendientes.length && !this.archivosPendientes.length) {
          this.permiteEjecutar = true
        } else {
          this.alert= true
        }
      }
    },
    "archivosPendientes"(newVal) {
       if(this.ejecucionesPendientes != undefined && this.archivosPendientes != undefined) {
        if(!this.ejecucionesPendientes.length && !this.archivosPendientes.length) {
          this.permiteEjecutar = true
        } else {
          this.alert= true
        }
      }
    },
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    console.log("api");
    console.log(api.defaults.baseURL);
    if (!this.permissions.includes("Definición de layouts"))
      this.$router.push("/dashboard");

    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });
    // this.fetchEjecucionesPendientesProcesosCalidad();
    // this.fetchArchivosPendientes();

    if (localStorage.getItem("datos_layout")) {
      this.layout = JSON.parse(localStorage.getItem("datos_layout"));
      console.log(this.layout);
    }
    this.fetchItems("");
  },
  methods: {
    toKebabCase(str) {
      return str.replace(/([a-z])([A-Z0-9])/g, "$1-$2").toLowerCase();
    },
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      ArchivoService.getItems(this.layout.id, cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          console.log(this.items)
          this.items = this.items.map(function f(x) {
            let usuario = x.usuario?x.usuario.name:null
            return { ...x, usuario: usuario };
          });
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    fetchEjecucionesPendientesProcesosCalidad() {
      EjecucionProcesoCalidadService.getEjecucionesPendientes().then(
        (response) => {
          this.ejecucionesPendientes = response.data;
          console.log('ejecuciones en curso...')
          console.log(response.data);
        },
        (error) => { 
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    fetchArchivosPendientes() {
      ArchivoService.getArchivosPendientes().then(
        (response) => {
          this.archivosPendientes = response.data;
          console.log('arvhivos en curso...')
          console.log(response.data);
        },
        (error) => { 
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    crear() {
      localStorage.removeItem("datos_archivo");
      this.$router.push({ path: `archivos/cargar/` });
    },
    descargar(item) {
      ArchivoService.getArchivo(this.layout.id, item).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = item.nombre;
          this.saveAs(blob, fileName);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    saveAs(blob, fileName) {
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = fileName;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    preEliminar(item) {
      this.deleteItem = item;
      this.dialog = true;
    },
    cargar_bd(item) {
      ArchivoService.cargarBD(this.layout.id, item).then(
        (response) => {
          console.log(response.data);
          this.fetchItems("");
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    eliminar() {
      this.loading = true;
      this.dialog = false;
      ArchivoService.deleteItem(this.layout.id, this.deleteItem).then(
        (response) => {
          console.log(response.data);
          this.fetchItems("");
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    generarVariables(item, index) {
      this.items[index].estado = 'Pendiente'
      ArchivoService.generarVariables(this.layout.id, item).then(
        (response) => {
          console.log(response)
          alert('Se envió la solicitud de procesamiento para la generación de variables.')
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      ); 
    },
    descargarVariables(item) {
      ArchivoService.getArchivoVariables(this.layout.id, item).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `variables_${item.nombre}.csv`;
          this.saveAs(blob, fileName);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );  
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/layouts" });
    },
    obtenerFechaCorta(fecha) {
      const dateObj = new Date(fecha);
      const opciones = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      //const fechaCorta = dateObj.toLocaleDateString("es-ES", opciones) + " " + dateObj.toLocaleTimeString("es-ES", opciones);
      const fechaCorta = dateObj.toLocaleDateString("es-ES", opciones);
      return fechaCorta;
    }
  },
};
</script>

<style scoped>
.color-svg {
  fill: green;
}
</style>